<script setup>
import { computed } from 'vue';

const props = defineProps({
    open: String,
    total: String,
});

const openInt = computed(() => parseInt(props.open));
const totalInt = computed(() => parseInt(props.total));
</script>
<template>
    <span
        v-if="total !== '0'"
        class="px-2 py-0.5 font-bold text-white rounded"
        :class="{
            'bg-orange': totalInt - openInt > 0 && openInt != 0,
            'bg-green': openInt == 0,
            'bg-transparent': totalInt == 0,
            'bg-red': openInt == totalInt,
        }"
    >
        {{ total - open }}/{{ total }}
    </span>
</template>